import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './Head.module.sass';
import Icon from '../../../../components/Icon';
import { IUserListItem } from '@types';
import { paths } from 'router/paths';

type HeadProps = {
  className?: string;
  details: IUserListItem;
  openUpdateScoreModal: () => void;
};

const Head: React.FC<HeadProps> = ({ className, details, openUpdateScoreModal }) => {
  const navigate = useNavigate();
  const navigateToDetails = (id: string) => {
    navigate(paths.USER_MODIFY_PATH.replace(':id', id));
  };
  return (
    <div className={cn(styles.head, className)}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <img src={details.profilePhoto} alt="Avatar" />
        </div>
        <div className={styles.details}>
          <div className={styles.man}>
            {details.firstName} {details.lastName}
          </div>
          <div className={styles.login}>{details.email}</div>
        </div>
      </div>
      <div className={styles.btns}>
        <button onClick={() => navigateToDetails(details.id)} className={cn('button-stroke', styles.button)}>
          <span>რედაქტირება</span>
          <Icon name="edit" size="24" />
        </button>
        <button onClick={() => openUpdateScoreModal()} className={cn('button-stroke', styles.button)}>
          <span>ქულა</span>
          <Icon name="edit" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Head;
