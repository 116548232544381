import cn from 'classnames';
import moment from 'moment';

import styles from './Row.module.sass';
import { IUserScoreLogListItem } from '@types';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

interface RowProps {
  item: IUserScoreLogListItem;
}

const Row = ({ item }: RowProps) => {
  const navigate = useNavigate();
  const formatedDate = moment(item?.createdAt).format('DD/MM/YYYY HH:mm');

  let additionInfoData;

  if (item.type === 'event') {
    additionInfoData = JSON.parse(item.properties.event) as { id: string; title: string; date: string };
  }

  const navigateToDetails = (id: string) => {
    navigate(paths.EVENTS_DETAILS_PATH.replace(':id', id));
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div
            className={cn(
              {
                [styles.red]: item.score < 0,
              },
              { [styles.green]: item.score > 0 },
            )}
          >
            {item.score}
          </div>
        </div>

        <div className={styles.col}>
          <div>{formatedDate}</div>
        </div>

        <div className={styles.col}>
          <div>{item.type}</div>
        </div>

        <div className={styles.col}>{<>{item.properties.text}</>}</div>
        <div className={styles.col}>
          {item.type === 'event' && (
            <div className={styles.eventName} onClick={() => navigateToDetails(additionInfoData?.id)}>
              {additionInfoData?.title}
            </div>
          )}
          {item.type === 'manual' && <div>{item.properties.details}</div>}
        </div>
      </div>
    </>
  );
};

export default Row;
