import React, { useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import styles from './GeneralInformation.module.sass';
import Card from 'components/Card';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import File from 'components/File';
import { paths } from 'router/paths';
import { getErrorMessage } from '@core';

interface GeneralInformationProps {
  className?: string;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({ className }) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [imageControlValue, setImageControlValue] = React.useState();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      handleImageChange(value.profilePhoto);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleImageChange = (value: any) => {
    if (value?.[0]?.name) {
      const currentFile = value?.[0];
      if (currentFile) {
        setImageControlValue(URL.createObjectURL(currentFile));
      }
    } else if (typeof value === 'string') {
      setImageControlValue(value);
    }
  };

  const updateDate = (date: Date, name: string) => {
    setValue(name, date, { shouldValidate: true });
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="ზოგადი ინფორმაცია"
      classTitle="title-green"
      head={
        <Link className={cn('button-stroke button-small', styles.button)} to={paths.EVENTS_LIST_PATH}>
          <Icon name="arrow-left" size="24" />
          <span>უკან</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <File
          error={getErrorMessage(errors, 'profilePhoto')}
          className={styles.field}
          name="profilePhoto"
          title="ფოტოს ატვირთვა"
          type="image"
          label="ფოტო"
          value={imageControlValue}
          register={register}
        />
        <TextInput
          className={styles.field}
          label="სახელი"
          name="firstName"
          error={getErrorMessage(errors, 'firstName')}
          type="text"
          register={register}
        />

        <TextInput
          className={styles.field}
          label="გვარი"
          name="lastName"
          error={getErrorMessage(errors, 'lastName')}
          type="text"
          register={register}
        />

        <TextInput
          className={styles.field}
          label="იმეილი"
          name="email"
          error={getErrorMessage(errors, 'email')}
          type="email"
          register={register}
        />

        <TextInput
          className={styles.field}
          label="ტელეფონი"
          name="phoneNumber"
          error={getErrorMessage(errors, 'phoneNumber')}
          type="text"
          register={register}
        />

        <TextInput
          className={styles.field}
          label="ქალაქი"
          name="city"
          error={getErrorMessage(errors, 'city')}
          type="text"
          register={register}
        />

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            თარიღი
            {getErrorMessage(errors, 'date') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'date')}</span>
            )}
          </div>

          <DatePicker
            selected={getValues('birthday')}
            locale="ka"
            dateFormat={'dd/M/yyyy'}
            onChange={date => updateDate(date, 'birthday')}
          />
        </div>
      </div>
    </Card>
  );
};

export default GeneralInformation;
