import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from './NewProduct.module.sass';
import GeneralInformation from './GeneralInformation';
import Panel from './Panel';
import { createUser, getUserDetails, isValidImageType, updateUserDetails } from '@core';

import { OverlayLoader } from 'components/Loader';
import { UserCreatePayload } from '@types';
import { paths } from 'router/paths';

const schema = yup.object().shape({
  firstName: yup.string().required('სავალდებულოა'),
  lastName: yup.string().required('სავალდებულოა'),
  email: yup.string().email().required('სავალდებულოა'),
  phoneNumber: yup.string().required('სავალდებულოა'),
  city: yup.string().required('სავალდებულოა'),
  gender: yup.string().required('სავალდებულოა'),
  birthday: yup.date().required('სავალდებულოა'),
  profilePhoto: yup
    .mixed()
    .required('ველი სავალდებულოა')
    .test('is-valid-image-type', 'სავალდებულოა', value => {
      return isValidImageType(value);
    }),
});

type FormTypes = 'add' | 'edit';

const ModifyUser = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [formType, setFormType] = React.useState<FormTypes>('add');
  const formMethods = useForm<UserCreatePayload>({ resolver: yupResolver(schema) });
  const [isAdding, setIsAdding] = React.useState(false);

  const onSubmit = () => {
    void formMethods.handleSubmit(
      data => {
        setIsAdding(true);

        let requestData = {
          ...data,
        };
        if (data.profilePhoto[0] instanceof File) {
          requestData = {
            ...data,
            profilePhoto: data.profilePhoto[0],
          };
        }

        const promise = formType === 'edit' ? updateUserDetails(params.id, requestData) : createUser(requestData);

        promise
          .then(response => {
            console.log(response, 'response');
            toast.success('წარმატებით დაემატა');
            navigate(paths.USER_DETAILS_PATH.replace(':id', response.data.user.id));
          })
          .catch(() => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (errors, event) => {
        console.log(errors, event, 'validation errors');
      },
    )();
  };

  useEffect(() => {
    if (params.id) {
      setFormType('edit');
      void getUserDetails(params.id).then(response => {
        formMethods.reset({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          birthday: moment(response.data.birthday).toDate(),
          profilePhoto: response.data.profilePhoto,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          city: response.data.city,
          gender: response.data.gender,
        });
      });
    } else {
      formMethods.reset({
        gender: 'Male',
      });
    }
  }, [params.id]);

  return (
    <>
      <FormProvider {...formMethods}>
        {isAdding && <OverlayLoader />}
        <>
          <div className={styles.row}>
            <div className={styles.col}>
              <GeneralInformation className={styles.card} />
            </div>
          </div>
          <Panel onSubmit={onSubmit} />
        </>
      </FormProvider>
    </>
  );
};

export default ModifyUser;
