import { httpClient } from '../http-client';

export const getTranslatedResource = async (lang: string) => {
  const response = await httpClient.get(`translate/${lang}`);
  return response.data;
};

export const saveTranslatedData = async (lang: string, data) => {
  const response = await httpClient.put(`translate/${lang}`, data);
  return response.data;
};
