// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination ul {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border-radius: 50px;
}

.pagination ul li {
  list-style: none;
}

.pagination ul li {
  color: #2a85ff;
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}

.pagination ul li a {
  list-style: none;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  line-height: 45px;
  border-radius: 50%;
  display: block;
}

.pagination ul li.first a {
  margin: 0px 3px 0 -5px;
}

.pagination ul li.last a {
  margin: 0px -5px 0 3px;
}

.pagination ul li.next a,
.pagination ul li.prev a {
  padding: 0 20px;
  border-radius: 50px;
}

.pagination li.selected a,
.pagination ul li:hover a,
.pagination ul li:first-child:hover a,
.pagination ul li:last-child:hover a {
  color: #fff;
  background: #2a85ff;
}`, "",{"version":3,"sources":["webpack://./src/styles/pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAGF;;AADA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AAIF;;AAFA;EACE,sBAAA;AAKF;;AAHA;EACE,sBAAA;AAMF;;AAHA;;EAEE,eAAA;EACA,mBAAA;AAMF;;AAJA;;;;EAIE,WAAA;EACA,mBAAA;AAOF","sourcesContent":[".pagination ul {\n  display: flex;\n  flex-wrap: wrap;\n  padding: 8px;\n  border-radius: 50px;\n}\n.pagination ul li {\n  list-style: none;\n}\n.pagination ul li {\n  color: #2a85ff;\n  list-style: none;\n  line-height: 45px;\n  text-align: center;\n  font-size: 18px;\n  font-weight: 500;\n  cursor: pointer;\n  user-select: none;\n  transition: all 0.3s ease;\n}\n.pagination ul li a {\n  list-style: none;\n  height: 45px;\n  width: 45px;\n  margin: 0 3px;\n  line-height: 45px;\n  border-radius: 50%;\n  display: block;\n}\n.pagination ul li.first a {\n  margin: 0px 3px 0 -5px;\n}\n.pagination ul li.last a {\n  margin: 0px -5px 0 3px;\n}\n\n.pagination ul li.next a,\n.pagination ul li.prev a {\n  padding: 0 20px;\n  border-radius: 50px;\n}\n.pagination li.selected a,\n.pagination ul li:hover a,\n.pagination ul li:first-child:hover a,\n.pagination ul li:last-child:hover a {\n  color: #fff;\n  background: #2a85ff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
