import React from 'react';
import cn from 'classnames';

import styles from './Profile.module.sass';
import { IEventListItem } from '@types';

const Profile: React.FC<{ event: IEventListItem }> = ({ event }) => {
  return (
    <div className={styles.profile}>
      <div className={styles.details}>
        <div className={styles.wrap}>
          <div className={cn('h4', styles.man)}>{event.title}</div>
          <div className={styles.info}>თარიღი - {event.date}</div>
          <div className={styles.info}>ბილეთების დაწყება - {event.joinRequestStartDate}</div>
          <div className={styles.info}>ბილეთების დასრულება - {event.joinRequestEndDate}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
