import styles from './Home.module.sass';
import Overview from './Overview';
import ProductViews from './Overview/ProductViews';

const Home = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview className={styles.card} />
          <ProductViews className={styles.card} />
        </div>
      </div>
    </>
  );
};

export default Home;
