import { useAsync } from 'react-async';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { JsonEditor } from 'json-edit-react';

import { getTranslatedResource, saveTranslatedData } from 'core/services/komagebi/translate';
import { toast } from 'react-toastify';

export const TranslatePage = () => {
  const { data, isLoading, reload, run } = useAsync({ deferFn: getTranslatedResource });
  const params = useParams();
  const lang = params.lang;

  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    run(lang);
  }, [lang, run]);

  const jsonChange = current => {
    setUpdatedData(current);
  };

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  const onHandleSave = async () => {
    try {
      const result = await saveTranslatedData(lang, updatedData);
      console.log(result);
      toast.success('წარმატებით შეინახა');
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  return (
    <div>
      <h1>ტექტსტები - {lang}</h1>

      {data && (
        <>
          <JsonEditor data={updatedData} restrictDelete setData={jsonChange} />
          <div onClick={onHandleSave} className={'button button-small'}>
            <span>შენახვა</span>
          </div>
        </>
      )}
    </div>
  );
};
