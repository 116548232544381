import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './details.module.sass';
import Profile from './Profile';
import Card from 'components/Card';
import { getEvent } from '@core';
import { IEventListItem } from '@types';
import { Reports } from './Reports';
import { TicketRequestList } from './TicketRequestList';

// data

const navigation = ['ბილეთის მოთხოვნები', 'რეპორტები'];

const EventDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [activeIndex, setActiveIndex] = useState(0);
  const [event, setEvent] = useState<IEventListItem>();

  useEffect(() => {
    if (params.id) {
      void getEvent(params.id).then(response => {
        setEvent(response.data);
      });
    }
  }, [params.id]);

  return (
    <>
      <div className={styles.shop}>
        {event && (
          <>
            <div className={styles.background}>
              <img src={event.imageUrl} alt="Background" />
            </div>
            <Card className={styles.card}>
              <Profile event={event} />

              <div className={styles.control}>
                <div className={styles.nav}>
                  {navigation.map((x, index) => (
                    <button
                      className={cn(styles.link, {
                        [styles.active]: index === activeIndex,
                      })}
                      onClick={() => setActiveIndex(index)}
                      key={index}
                    >
                      {x}
                    </button>
                  ))}
                </div>
              </div>
              <div className={styles.wrap}>
                {activeIndex === 0 && (
                  <>
                    <TicketRequestList maxTicketCount={event.availableTicketsCount} />
                  </>
                )}

                {activeIndex === 1 && (
                  <>
                    <Reports />
                  </>
                )}
              </div>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default EventDetails;
