import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './Row.module.sass';
import { IEventListItem } from '@types';
import { getFullImagePath } from 'core/utils/image';
import Icon from 'components/Icon';
import { paths } from 'router/paths';

interface RowProps {
  item: IEventListItem;
}

const Row = ({ item }: RowProps) => {
  const navigate = useNavigate();

  const navigateToDetails = (id: string) => {
    navigate(paths.TICKET_DETAILS_PATH.replace(':id', id));
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div className={styles.item} onClick={() => navigateToDetails(item.id)}>
            <div className={styles.photo}>
              <img src={getFullImagePath(item.imageUrl)} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item.title}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>{item.date}</div>
        <div className={styles.col}>
          <button
            onClick={() => navigateToDetails(item.id)}
            className={cn('button', 'button-small', 'button-stroke', styles.button)}
          >
            <Icon name="ticket" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
