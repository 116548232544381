// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d5ZUPq6uwojRoxL7VYhd {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Paginate/Paginate.module.sass"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;AAFJ","sourcesContent":["@import ../../styles/helpers\n\n\n.pagination\n    display: flex\n    justify-content: center\n    margin-top: 1rem\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `d5ZUPq6uwojRoxL7VYhd`
};
export default ___CSS_LOADER_EXPORT___;
