import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { SelectedOption, SelectedOptionValue, SelectSearchProps } from 'react-select-search';

import styles from './modify.module.sass';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import { createTicket, getErrorMessage } from '@core';
import { OverlayLoader } from 'components/Loader';
import { UserSelector } from 'components/UserSelector';

const schema = yup.object().shape({
  quantity: yup.number().min(0).required('სავალდებულოა'),
  userId: yup.string().required('სავალდებულოა'),
});

const CreateTicket: React.FC<{
  eventId: string;
  success: () => void;
}> = ({ eventId, success }) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ quantity: number; userId: string }>({ resolver: yupResolver(schema) });

  const [isAdding, setIsAdding] = useState(false);

  const onSubmit = () => {
    void handleSubmit(
      data => {
        setIsAdding(true);

        createTicket(eventId, data)
          .then(() => {
            toast.success('წარმატებით შეიქმნა ბილეტი');
            success();
          })
          .catch((err: AxiosError) => {
            if (err?.response?.data?.message && err?.response?.data?.message.includes('ticket.already-requested')) {
              toast.error('ბილეთების მითითებულ ივენთზე მომხმარებელს უკვე აქვს მოთხოვნილი');
            } else {
            }
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (err, event) => {
        console.log(err, event, 'validation errors');
      },
    )();
  };

  const onUserChange = (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps,
  ) => {
    console.log(selectedOption);
    setValue('userId', selectedOption.id, { shouldValidate: true });
  };

  return (
    <div className={styles.post}>
      {isAdding && <OverlayLoader />}
      <div className={cn('title-green', styles.title)}>ბილეთის შექმნა</div>

      <div className={styles.field}>
        <label>მომხმარებელი</label>
        <UserSelector onChange={onUserChange} />
      </div>
      <br />
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="რაოდენობა"
          name="quantity"
          error={getErrorMessage(errors, 'quantity')}
          type="number"
          register={register}
        />
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn('button', styles.button)}>
          <span>შექმნა</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default CreateTicket;
