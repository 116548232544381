import { useState } from 'react';
import { useAsync } from 'react-async';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { deliverTicket, getApprovedTicketList } from '@core';
import Card from 'components/Card';
import Form from 'components/Form';
import styles from './TicketRequestList.module.sass';
import Table from './Table';
import { IEventTicketListItem } from '@types';

export const TicketRequestList = () => {
  const params = useParams();

  const {
    data: usersList,
    isLoading: isUserListLoading,
    reload,
  } = useAsync({
    promiseFn: getApprovedTicketList,
    id: params.id,
  });

  const [search, setSearch] = useState('');

  const handleApproveClickClick = async (item: IEventTicketListItem) => {
    try {
      const result = await deliverTicket(item.id);
      if (result.data) {
        toast.info('წარმატებულით მიენიჭა ბილეთი');
        reload();
        return;
      }
    } catch {
      toast.error('დაფიქსირდა შეცდომა, თავიდან სცადეთ');
    }
  };

  return (
    <>
      <Card className={styles.card} classTitle={cn('title-purple', styles.title)}>
        <br />
        <div className={styles.search}>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onSubmit={() => {}}
            placeholder="ძებნა"
            type="text"
            name="search"
            icon="search"
          />
        </div>
        <div className={cn(styles.row, { [styles.flex]: true })}>
          <Table
            tickets={usersList}
            isRequestLoading={isUserListLoading}
            onApprove={item => void handleApproveClickClick(item)}
            className={styles.table}
          />
        </div>
      </Card>
    </>
  );
};
