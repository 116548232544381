import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import moment from 'moment';

import styles from './NewProduct.module.sass';
import GeneralInformation from './GeneralInformation';
import Panel from './Panel';
import { createEvent, getEvent, getRankingList, isValidImageType, LONG_DATE_FORMAT, updateEvent } from '@core';

import { OverlayLoader } from 'components/Loader';
import { EventCreatePayload } from '@types';
import CheckInDetails from './CheckInDetails';
import Ranks from './Ranks';

const schema = yup.object().shape({
  title: yup.string().required('სავალდებულოა'),
  description: yup.string().required('სავალდებულოა'),
  date: yup.date().required('სავალდებულოა'),
  checkInStartDate: yup.date(),
  checkInEndDate: yup.date(),
  joinRequestStartDate: yup.date().required('სავალდებულოა'),
  joinRequestEndDate: yup.date().required('სავალდებულოა'),
  ticketHandoutDate: yup.date().required('სავალდებულოა'),
  locationName: yup.string().required('სავალდებულოა'),
  checkinMapPolygon: yup.string(),
  checkInEnabled: yup.boolean().required(),
  ticketsEnabled: yup.boolean().required(),
  attendantScore: yup.number().min(0).typeError('სავალდებულოა').required('სავალდებულოა'),
  image: yup
    .mixed()
    .required('ველი სავალდებულოა')
    .test('is-valid-image-type', 'სავალდებულოა', value => {
      return isValidImageType(value);
    }),
  ranksRestriction: yup.array(
    yup
      .object({
        rankId: yup.string(),
        maxTicketNumber: yup.number().min(0).required('სავალდებულოა'),
      })
      .required('სავალდებულოა'),
  ),
});

type FormTypes = 'add' | 'edit';

const ModifyEvent = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [formType, setFormType] = React.useState<FormTypes>('add');
  const formMethods = useForm<EventCreatePayload>({ resolver: yupResolver(schema) });
  const [eventIsAdding, setEventIsAdding] = React.useState(false);

  const [savedCoordinates, setSavedCoordinates] = React.useState<string | null>(null);

  const onSubmit = () => {
    void formMethods.handleSubmit(
      data => {
        setEventIsAdding(true);

        let requestData = {
          ...data,
        };
        if (data.image[0] instanceof File) {
          requestData = {
            ...data,
            image: data.image[0],
          };
        }

        const promise = formType === 'edit' ? updateEvent(params.id, requestData) : createEvent(requestData);

        promise
          .then(() => {
            toast.success('წარმატებით დაემატა');
            navigate('/events');
          })
          .catch(() => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setEventIsAdding(false);
          });
      },
      (errors, event) => {
        console.log(errors, event, 'validation errors');
      },
    )();
  };

  const processEventRanks = (eventRanks?: { rankId: string; maxTicketNumber: number }[] | undefined) => {
    void getRankingList().then(ranks => {
      const updatedRankForms = ranks.data.map(rank => {
        const oldValue = eventRanks?.find(x => x.rankId === rank.id)?.maxTicketNumber || 0;
        return {
          rankId: rank.id,
          maxTicketNumber: oldValue,
          name: rank.name,
        };
      });

      formMethods.setValue('ranksRestriction', updatedRankForms, { shouldValidate: true });
    });
  };

  useEffect(() => {
    if (params.id) {
      setFormType('edit');
      void getEvent(params.id).then(response => {
        if (response.data.checkinMapPolygon) {
          setSavedCoordinates(response.data.checkinMapPolygon);
        }
        formMethods.reset({
          title: response.data.title,
          description: response.data.description,
          date: moment(response.data.date, LONG_DATE_FORMAT).toDate(),
          checkInStartDate: response.data.checkInStartDate
            ? moment(response.data.checkInStartDate, LONG_DATE_FORMAT).toDate()
            : undefined,
          checkInEndDate: response.data.checkInEndDate
            ? moment(response.data.checkInEndDate, LONG_DATE_FORMAT).toDate()
            : undefined,
          joinRequestStartDate: moment(response.data.joinRequestStartDate, LONG_DATE_FORMAT).toDate(),
          joinRequestEndDate: moment(response.data.joinRequestEndDate, LONG_DATE_FORMAT).toDate(),
          ticketHandoutDate: moment(response.data.ticketHandoutDate ?? new Date(), LONG_DATE_FORMAT).toDate(),
          image: response.data.imageUrl,
          ticketsEnabled: response.data.ticketsEnabled,
          attendantScore: response.data.attendantScore,
          availableTicketsCount: response.data.availableTicketsCount,
          locationName: response.data.locationName,
          checkInEnabled: response.data.checkInEnabled,
        });

        const eventRanks = response.data.ranks;
        processEventRanks(eventRanks);
      });
    } else {
      formMethods.reset({
        checkInEnabled: false,
        ticketsEnabled: false,
      });
      processEventRanks();
    }
  }, [params.id]);

  return (
    <>
      <FormProvider {...formMethods}>
        {eventIsAdding && <OverlayLoader />}
        <>
          <div className={styles.row}>
            <div className={styles.col}>
              <GeneralInformation className={styles.card} />
              <CheckInDetails defaultCoordinates={savedCoordinates} />
              <Ranks />
            </div>
          </div>
          <Panel onSubmit={onSubmit} />
        </>
      </FormProvider>
    </>
  );
};

export default ModifyEvent;
