import cn from 'classnames';
import moment from 'moment';

import styles from './Row.module.sass';
import { IUserEvenTicketListItem } from '@types';

interface RowProps {
  item: IUserEvenTicketListItem;
}

const Row = ({ item }: RowProps) => {
  const formatedEventDate = moment(item?.event.date).format('DD/MM/YYYY HH:mm');

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.avatar}>
              <img
                src={item.event.imageUrl !== '' ? item.event.imageUrl : '/images/content/avatar-2.jpg'}
                alt="Profile Photo"
              />
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div>{item.event.title} </div>
        </div>

        <div className={styles.col}>
          <div>{formatedEventDate}</div>
        </div>

        <div className={styles.col}>
          <div>{item.quantity}</div>
        </div>

        <div className={styles.col}>
          <div>{item.event.attendantScore}</div>
        </div>
      </div>
    </>
  );
};

export default Row;
