import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-async';

import styles from './CustomerList.module.sass';
import Card from '../../components/Card';
import Form from '../../components/Form';
import Table from './Table';
import { exportUsersToExcel, getUserList } from 'core/services/komagebi/users';
import Icon from 'components/Icon';
import { paths } from 'router/paths';

const UserListPage = () => {
  const [page, setPage] = useState(1);
  const {
    data: usersResponse,
    isLoading: isUserListLoading,
    run,
  } = useAsync({
    deferFn: getUserList,
  });

  const [search, setSearch] = useState('');

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      run(page, search);
    }, 400); // 400ms debounce delay

    // Cleanup timeout if dependencies change before the delay finishes
    return () => clearTimeout(debounceTimeout);
  }, [page, search, run]);

  const handleExport = () => {
    void exportUsersToExcel(search);
  };

  return (
    <>
      <Card
        className={styles.card}
        title="სია"
        classTitle={cn('title-purple', styles.title)}
        classCardHead={cn(styles.head)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => {}}
              placeholder="ძიება"
              type="text"
              name="search"
              icon="search"
            />
            <div className={cn('button', 'button-small', styles.button, styles.exportButton)} onClick={handleExport}>
              <span>ექსპორტი</span>
            </div>
            <Link className={cn('button', 'button-small', styles.button)} to={paths.USER_ADD_PATH}>
              <Icon name="add" size="24" />
              <span>დამატება</span>
            </Link>
          </>
        }
      >
        <div className={cn(styles.row)}>
          <Table
            users={usersResponse}
            isRequestLoading={isUserListLoading}
            className={styles.table}
            pageCount={usersResponse?.data.meta.totalPages ?? 0}
            handlePageChange={(selectedItem: { selected: number }) => setPage(selectedItem.selected + 1)}
          />
        </div>
      </Card>
    </>
  );
};

export default UserListPage;
