import SelectSearch, { SelectedOption, SelectedOptionValue, SelectSearchProps } from 'react-select-search';

import { filterUserByName } from '@core';
import { useState } from 'react';
import { set } from 'lodash';

interface IProps {
  onChange: (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps,
  ) => void;
}

export const UserSelector: React.FC<IProps> = ({ onChange }) => {
  const [selectedUser, setSelectedUser] = useState<SelectedOption>();

  function renderUser(props, option, snapshot, className) {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };

    return (
      <button {...props} className={className} type="button">
        <span>
          <img alt="" style={imgStyle} width="28" height="28" src={option.profilePhoto} />
          <span>{option.name}</span>
        </span>
      </button>
    );
  }

  const getUserOptions = (query: string) => {
    return new Promise((resolve, reject) => {
      if (query.length < 3) {
        resolve([]);
        return;
      }
      filterUserByName(query)
        .then(resp => {
          resolve(
            resp.data.map(user => ({
              value: user.id,
              name: `${user.firstName} ${user.lastName}`,
              profilePhoto: user.profilePhoto,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              id: user.id,
              group: `${user.email} ${user.phoneNumber}`,
            })),
          );
        })
        .catch(reject);
    });
  };

  return (
    <>
      {selectedUser && (
        <div>
          {selectedUser.name} - {selectedUser.email} - {selectedUser.phoneNumber}
        </div>
      )}
      <SelectSearch
        options={[]}
        onChange={(
          selectedValue: SelectedOptionValue | SelectedOptionValue[],
          selectedOption: SelectedOption | SelectedOption[],
          optionSnapshot: SelectSearchProps,
        ) => {
          setSelectedUser(selectedOption);
          onChange(selectedValue, selectedOption, optionSnapshot);
        }}
        renderOption={renderUser}
        getOptions={getUserOptions}
        search
        placeholder="აირჩიეთ"
      />
    </>
  );
};
