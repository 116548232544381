import ReactPaginate from 'react-paginate';
import cn from 'classnames';

import styles from './Paginate.module.sass';

interface PaginateProps {
  handlePageClick: (selectedItem: { selected: number }) => void;
  pageCount: number;
}

export const Paginate: React.FC<PaginateProps> = ({ handlePageClick, pageCount }) => {
  return (
    <div className={cn(styles.pagination, 'pagination')}>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};
