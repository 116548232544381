import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import styles from './Details.module.sass';
import Icon from '../../../components/Icon';
import Head from './Head';
import { IUserListItem } from '@types';
import Parameter from './Parameter';
import { getUserDetails } from '@core';
import Modal from 'components/Modal';
import UpdateScore from './UpdateScore';
import { TicketRequestList } from './TicketRequestList';
import { ScoreHistory } from './ScoreHistory';

const navigation = ['ბილეთები', 'ქულების ცვლილებები'];

const UserDetails = () => {
  const params = useParams();

  const [user, setUser] = useState<IUserListItem>();
  const [visibleModal, setVisibleModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (params.id) {
      void getUserDetails(params.id).then(response => {
        setUser(response.data);
      });
    }
  }, [params.id]);

  const formatedBirthday = moment(user?.birthday).format('DD/MM/YYYY');

  const handleOpenUpdateScoreModal = () => {
    setVisibleModal(true);
  };

  const onApproveSuccess = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Modal outerClassName={styles.outer} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <UpdateScore user={user} success={onApproveSuccess} />
      </Modal>
      {user && (
        <div className={cn(styles.details)}>
          <Head className={styles.head} details={user} openUpdateScoreModal={handleOpenUpdateScoreModal} />

          <div className={styles.group}>
            <Parameter label="ქულა" value={user.score} />
            <Parameter label="მეილი" value={user.email} />
            <Parameter label="ქალაქი" value={user.city} />
            <Parameter label="დაბადების თარიღი" value={formatedBirthday} />
            <Parameter label="ნომერი" value={user.phoneNumber} />
          </div>

          <br />
          <div className={styles.control}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.wrap}>
            {activeIndex === 0 && (
              <>
                <TicketRequestList />
              </>
            )}

            {activeIndex === 1 && (
              <>
                <ScoreHistory />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
