import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import styles from './modify.module.sass';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import { IUserListItem } from '@types';
import { getErrorMessage, updateUserScore } from '@core';
import { OverlayLoader } from 'components/Loader';

const schema = yup.object().shape({
  score: yup.number().required('სავალდებულოა'),
  userId: yup.string().required('სავალდებულოა'),
  description: yup.string().required('სავალდებულოა'),
});

const UpdateScore: React.FC<{
  user: IUserListItem | undefined;
  success: () => void;
}> = ({ user, success }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ score: number; userId: string; description: string }>({ resolver: yupResolver(schema) });

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (user?.id) {
      setValue('userId', user.id);
    }
  }, [user, setValue]);

  const onSubmit = () => {
    void handleSubmit(
      data => {
        setIsAdding(true);

        updateUserScore(data)
          .then(() => {
            toast.success('განახლდა წარმატებით');
            if (user) {
              user.score += data.score;
              if (user.score < 0) {
                user.score = 0;
              }
            }
            success();
          })
          .catch((err: AxiosError) => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (err, event) => {
        console.log(err, event, 'validation errors');
      },
    )();
  };

  return (
    <div className={styles.post}>
      {isAdding && <OverlayLoader />}
      <div className={cn('title-green', styles.title)}>ქულის ცვლილება</div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="ქულა"
          name="score"
          error={getErrorMessage(errors, 'score')}
          type="number"
          register={register}
        />
      </div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="მიზეზი"
          name="description"
          error={getErrorMessage(errors, 'description')}
          type="text"
          register={register}
        />
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn('button', styles.button)}>
          <span>დადასტურება</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default UpdateScore;
