import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { registerLocale } from 'react-datepicker';

import './styles/app.sass';

import { Router } from './router/Router';
import { useAppDispatch } from './store';

import { accountActions } from './store/account';
import 'styles/react-select-search-styles.css';
import 'styles/pagination.css';
import 'react-toastify/dist/ReactToastify.css';

import ka from 'date-fns/locale/ka'; // the locale you want
registerLocale('ka', ka); // register it with the name you want

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(accountActions.init());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Router />
      <ToastContainer />
    </BrowserRouter>
  );
};
