import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import styles from './Users.module.sass';
import Icon from '../../../../components/Icon';
import { IUser } from '@types';
import { paths } from 'router/paths';

const Users = ({ className, users }: { users: IUser[] }) => {
  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.list}>
        {users.map((x, index) => (
          <Link className={styles.item} key={index} to={paths.USER_DETAILS_PATH.replace(':id', x.id)}>
            <div className={styles.avatar}>
              <img src={x.profilePhoto} alt="Avatar" />
            </div>
            <div className={styles.title}>{`${x.firstName} ${x.lastName}`}</div>
          </Link>
        ))}
        <Link className={styles.all} to={paths.USER_LIST_PATH}>
          <div className={styles.icon}>
            <Icon name="arrow-right" size="24" />
          </div>
          <div className={styles.text}>ყველას ნახვა</div>
        </Link>
      </div>
    </div>
  );
};

export default Users;
