import { useAsync } from 'react-async';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { getUserScoreLogs } from '@core';
import Card from 'components/Card';
import styles from './TicketRequestList.module.sass';
import Table from './Table';

export const ScoreHistory = () => {
  const params = useParams();

  const { data: usersList, isLoading: isUserListLoading } = useAsync({
    promiseFn: getUserScoreLogs,
    id: params.id,
  });

  return (
    <>
      <Card className={styles.card} classTitle={cn('title-purple', styles.title)}>
        <div className={cn(styles.row, { [styles.flex]: true })}>
          <Table logs={usersList?.data} isRequestLoading={isUserListLoading} className={styles.table} />
        </div>
      </Card>
    </>
  );
};
