import { useCallback, useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { declineTicket, getTicketCount, getEvenTicketList, exportTicketsToExcel } from '@core';
import Card from 'components/Card';
import Form from 'components/Form';
import styles from './TicketRequestList.module.sass';
import Table from './Table';
import Modal from 'components/Modal';
import { IEventTicketListItem } from '@types';
import ApproveTicket from './ApproveTicket';
import Icon from 'components/Icon';
import CreateTicket from './CreateTicket';

export const TicketRequestList: React.FC<{ maxTicketCount: number }> = ({ maxTicketCount }) => {
  const params = useParams();

  const [selectedTicket, setSelectedTicket] = useState<IEventTicketListItem | undefined>();
  const [visibleApproveTicketModal, setVisibleApproveTicketModal] = useState(false);
  const [visibleCreateTicketModal, setVisibleCreateTicketModal] = useState(false);

  const { data: approvedTicketCount, reload: reloadCount } = useAsync({
    promiseFn: getTicketCount,
    id: params.id,
  });

  const {
    data: usersList,
    isLoading: isUserListLoading,
    reload,
  } = useAsync({
    promiseFn: getEvenTicketList,
    id: params.id,
  });

  const [search, setSearch] = useState('');

  const handleDecline = useCallback(
    async (ticketId: string) => {
      try {
        const eventId = params.id;
        if (!eventId) {
          return;
        }
        const result = await declineTicket(eventId, ticketId);
        if (result.data) {
          toast.info('ჩანაწერი უარყოფილია');
          reload();
          reloadCount();
          return;
        }
      } catch {
        toast.error('დაფიქსირდა შეცდომა, თავიდან სცადეთ');
      }
    },
    [params.id],
  );

  const onApproveSuccess = () => {
    setSelectedTicket(undefined);
    setVisibleApproveTicketModal(false);
    reload();
    reloadCount();
  };

  const onCreateTicketSuccess = () => {
    setVisibleCreateTicketModal(false);
    reload();
    reloadCount();
  };

  const handleApproveClickClick = (item: IEventTicketListItem) => {
    setSelectedTicket(item);
    setVisibleApproveTicketModal(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      reloadCount();
    }, 20000); // 20 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [reloadCount]);

  const eventId = params.id || '';

  const handleExport = () => {
    void exportTicketsToExcel(eventId);
  };

  return (
    <>
      <Modal
        outerClassName={styles.outer}
        visible={visibleApproveTicketModal}
        onClose={() => setVisibleApproveTicketModal(false)}
      >
        <ApproveTicket ticket={selectedTicket} eventId={eventId} success={onApproveSuccess} />
      </Modal>

      <Modal
        outerClassName={styles.outer}
        visible={visibleCreateTicketModal}
        onClose={() => setVisibleCreateTicketModal(false)}
      >
        <CreateTicket eventId={eventId} success={onCreateTicketSuccess} />
      </Modal>

      <Card className={styles.card} classTitle={cn('title-purple', styles.title)}>
        <div>
          მაქსიმალური - {maxTicketCount} (დარჩენილი:
          {maxTicketCount - approvedTicketCount?.data.approved - approvedTicketCount?.data.delivered})
        </div>
        <div>დადასტურებული - {approvedTicketCount?.data.delivered + approvedTicketCount?.data.approved}</div>
        <div>გაცემული - {approvedTicketCount?.data.delivered}</div>
        <br />
        <div className={styles.search}>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onSubmit={() => {}}
            placeholder="ძებნა"
            type="text"
            name="search"
            icon="search"
          />
          <div className={styles.exportBtn}>
            <div className={cn('button', 'button-small', styles.button, styles.exportButton)} onClick={handleExport}>
              <span>ექსპორტი</span>
            </div>
            <div
              onClick={() => setVisibleCreateTicketModal(true)}
              className={cn('button', 'button-small', styles.button)}
            >
              <Icon name="add" size="24" />
              <span>დამატება</span>
            </div>
          </div>
        </div>
        <div className={cn(styles.row, { [styles.flex]: true })}>
          <Table
            tickets={usersList}
            isRequestLoading={isUserListLoading}
            onDecline={$event => void handleDecline($event)}
            onApprove={item => handleApproveClickClick(item)}
            className={styles.table}
          />
        </div>
      </Card>
    </>
  );
};
