import React from 'react';
import cn from 'classnames';

import styles from './Table.module.sass';
import { Loader } from 'components/Loader';
import Row from './Row';

// data
import { IUserScoreLogListItem } from '@types';

interface TableProps {
  className?: string;
  isRequestLoading: boolean;
  logs: IUserScoreLogListItem[] | undefined;
}

const Table: React.FC<TableProps> = ({ className, logs, isRequestLoading }) => {
  return (
    <>
      {isRequestLoading && <Loader />}
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.row, styles.active)}>
            <div className={styles.col}></div>
            <div className={styles.col}>ქულა</div>
            <div className={styles.col}>თარიღი</div>
            <div className={styles.col}>ტიპი</div>
            <div className={styles.col}>ტექსტი</div>
            <div className={styles.col}>დამატებითი მონაცემები</div>
          </div>

          {!isRequestLoading && logs?.map(log => <Row item={log} key={log.id} />)}
        </div>
      </div>
    </>
  );
};

export default Table;
