import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import styles from './Row.module.sass';
import { IUserListItem } from '@types';
import { paths } from 'router/paths';

interface RowProps {
  item: IUserListItem;
}

const Row = ({ item }: RowProps) => {
  const navigate = useNavigate();

  const navigateToDetails = (id: string) => {
    navigate(paths.USER_DETAILS_PATH.replace(':id', id));
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div className={styles.item} onClick={() => navigateToDetails(item.id)}>
            <div className={styles.avatar}>
              <img
                src={item.profilePhoto !== '' ? item.profilePhoto : '/images/content/avatar-2.jpg'}
                alt="Profile Photo"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{`${item.firstName} ${item.lastName}`}</div>
              <div className={styles.city}>{item.city}</div>
              <div className={styles.email}>{item.email}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.email}>{item.email}</div>
        </div>
        <div className={styles.col}>
          <div>{item.phoneNumber}</div>
        </div>
      </div>
    </>
  );
};

export default Row;
