// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GC3b5m6BVaNKUwNseGVo {
  width: auto;
  height: 274px;
  margin-left: -35px;
  margin-bottom: -10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/Overview/ProductViews/ProductViews.module.sass"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,aAAA;EACA,kBAAA;EACA,oBAAA;AADJ","sourcesContent":["@import ../../../../styles/helpers\n\n.chart\n    width: auto\n    height: 274px\n    margin-left: -35px\n    margin-bottom: -10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `GC3b5m6BVaNKUwNseGVo`
};
export default ___CSS_LOADER_EXPORT___;
