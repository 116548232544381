import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './details.module.sass';
import Profile from './Profile';
import Card from 'components/Card';
import { getEvent } from '@core';
import { IEventListItem } from '@types';
import { TicketRequestList } from './TicketRequestList';

const TicketEventDetails = () => {
  const params = useParams();

  const [event, setEvent] = useState<IEventListItem>();

  useEffect(() => {
    if (params.id) {
      void getEvent(params.id).then(response => {
        setEvent(response.data);
      });
    }
  }, [params.id]);

  return (
    <>
      <div className={styles.shop}>
        {event && (
          <>
            <div className={styles.background}>
              <img src={event.imageUrl} alt="Background" />
            </div>
            <Card className={styles.card}>
              <Profile event={event} />
              <div className={styles.wrap}>
                <TicketRequestList />
              </div>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default TicketEventDetails;
