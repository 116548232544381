import cn from 'classnames';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useAsync } from 'react-async';

import Card from 'components/Card';
import useDarkMode from 'hooks/useDarkMode';
import styles from './ProductViews.module.sass';
import { getRegisteredReports } from '@core';

const ProductViews = ({ className }) => {
  const darkMode = useDarkMode(false);

  const { data, isLoading } = useAsync(getRegisteredReports);

  return (
    <Card className={cn(styles.card, className)} title="რეგისტრაცია" classTitle="title-purple">
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          {isLoading && <div>Loading...</div>}
          {data && (
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barSize={32}
              barGap={8}
            >
              <CartesianGrid strokeDasharray="none" stroke={darkMode.value ? '#272B30' : '#EFEFEF'} vertical={false} />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: '500', fill: '#6F767E' }}
                padding={{ left: 10 }}
              />
              <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12, fontWeight: '500', fill: '#6F767E' }} />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#272B30',
                  borderColor: 'rgba(255, 255, 255, 0.12)',
                  borderRadius: 8,
                  boxShadow:
                    '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000',
                }}
                labelStyle={{ fontSize: 12, fontWeight: '500', color: '#fff' }}
                itemStyle={{
                  padding: 0,
                  textTransform: 'capitalize',
                  fontSize: 12,
                  fontWeight: '600',
                  color: '#fff',
                }}
                cursor={{ fill: '#f3f2f3' }}
              />
              <Bar dataKey="count" fill="#B5E4CA" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default ProductViews;
